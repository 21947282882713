/* Fonts */

@font-face {
  font-family: "manrope-regular";
  src: url("/public/fonts/manrope/manrope-regular.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "manrope-light";
  src: url("/public/fonts/manrope/manrope-light.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "manrope-medium";
  src: url("/public/fonts/manrope/manrope-medium.otf");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "manrope-bold";
  src: url("/public/fonts/manrope/manrope-bold.otf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "manrope-semibold";
  src: url("/public/fonts/manrope/manrope-semibold.otf");
  font-weight: 600;
  font-style: normal;
}

/* Reset CSS */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
}

body {
  font-family:  "manrope-regular", "manrope-light", "manrope-bold" sans-serif;
  overflow-x: hidden;
}

img{
  max-width: 100%;
}

html{
  min-height: 100%;;
}
body, html, #root{
  height: 100%;
  width: 100%;
  /* background: #f5f5f5; */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chart-legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 10px;
}

.chart-legend-item {
  width: 600px; /* Set a fixed width */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 10px; /* Add some margin for spacing */
  display: flex;
  align-items: center;
}

.chart-legend-item span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 50%;
}


.no-break {
  page-break-inside: avoid;
}

.printContent {
  display: none !important;

  @media print {
    display: block !important;
  }
}